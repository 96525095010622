const schema = `type IdAndDescription {
  id: Float!
  description: String!
}

type IdAndName {
  id: Float!
  name: String!
}

type SimWorkspaceMigrationRequestValidationSimDetailsResponse {
  id: Float!
  iccid: String!
  iccid_with_luhn: String!
  endpoint: IdAndName
  status: IdAndDescription!
  source_workspace: IdAndName
}

type SimWorkspaceMigrationResponse {
  """The amount of SIMs to be migrated"""
  sim_count: Float!

  """The \`dry_run\` option specified in the request"""
  dry_run: Boolean!

  """The \`allowed_sim_statuses\` option specified in the request"""
  allowed_sim_statuses: [IdAndDescription!]

  """The \`target_workspace\` specified in the request"""
  target_workspace: IdAndName!

  """The \`auto_detach_devices\` option specified in the request"""
  auto_detach_devices: Boolean!

  """List of SIMs to be migrated"""
  sims: [SimWorkspaceMigrationRequestValidationSimDetailsResponse!]!
}

type SimResellerMigrationRequestValidationSimDetailsResponse {
  id: Float!
  iccid: String!
  iccid_with_luhn: String!
  endpoint: IdAndName
  status: IdAndDescription!
  reseller_organisation: IdAndName
}

type Country {
  id: Float!
  name: String!
  country_code: String!
  iso_code: String!
  mcc: String!
  latitude: String
  longitude: String
}

type LocationOperator {
  id: Float!
  name: String!
  country_id: Float!
  country_name: String!
}

type Location {
  iccid: String!
  imsi: String!
  last_updated: DateTime
  last_updated_gprs: DateTime
  sgsn_number: String
  vlr_number: String
  msc: String
  sgsn_ip_address: String
  country: Country
  operator: LocationOperator
}

"""
A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format.
"""
scalar DateTime

type RatType {
  id: Float!
  description: String!
}

type PdpContext {
  id: String!
  duration: String!
  teid_data_plane: Float!
  teid_control_plane: Float!
  gtp_version: Float!
  nsapi: Float!
  sgsn_control_plane_ip_address: String!
  sgsn_data_plane_ip_address: String!
  ggsn_control_plane_ip_address: String!
  ggsn_data_plane_ip_address: String!
  created: DateTime!
  mcc: String
  mnc: String
  lac: Float
  ci: Float
  sac: Float
  rac: Float
  ue_ip_address: String!
  imeisv: String
  region: String
  breakout_ip: String
  rat_type: RatType
}

type Connectivity {
  status: String!
  location: Location
  pdp_context: PdpContext
  services: [String!]
  is_satellite_geo: Boolean
}

type ListMetaObject {
  count: Int!
  pages: Int!
  per_page: Int!
}

type EndpointStatus {
  id: Int!
  description: String!
}

type TariffProfile {
  id: Float!
  name: String!
}

type SimStatus {
  id: Int!
  description: String!
}

type Currency {
  id: Int!
  code: String!
  symbol: String
}

type FederationProvider {
  id: Int!
  activated_date: DateTime!
  verified_date: DateTime!
}

type OrganisationClass {
  id: Int!
  description: String!
}

type OrganisationRelationType {
  id: Int!
  description: String!
}

type OrganisationRelation {
  id: Int!
  type: OrganisationRelationType!
}

type OrganisationStatus {
  id: Int!
  description: String!
}

type OrganisationType {
  id: Int!
  description: String!
}

type VerificationType {
  id: Int!
  description: String!
}

type Organisation {
  id: Int!
  name: String!
  type: OrganisationType!
  created: DateTime!
  currency: Currency
  monthly_cost_limit: Float
  country: Country
  class: OrganisationClass!
  status: OrganisationStatus!
  relations: [OrganisationRelation!]
  ext_reference: String
  verification: String
  verification_type: VerificationType
  federation_provider: FederationProvider!
}

type SimFormfactor {
  id: Int!
  name: String!
}

type SimProfile {
  id: Int!
  description: String!
}

type SimManufacturer {
  id: Int!
  name: String!
}

type Model {
  id: Int!
  description: String!
  memory_size: Float!
  formfactor: SimFormfactor
  manufacturer: SimManufacturer
}

type SimOrganisation {
  id: Int!
  name: String!
  type: OrganisationType!
  created: DateTime!
  currency: Currency
  monthly_cost_limit: Float
  country: Country
  class: OrganisationClass!
  status: OrganisationStatus!
  relations: [OrganisationRelation!]
  ext_reference: String
  verification: String
  verification_type: VerificationType
  federation_provider: FederationProvider!
}

type Sim {
  id: Int!
  iccid: String!

  """
  Integrated circuit card identifier including the final Luhn checksum digit
  """
  iccid_with_luhn: String
  production_date: DateTime
  activation_date: DateTime
  status: SimStatus!
  endpoint: Endpoint
  customer_org: SimOrganisation!
  issuer_org: SimOrganisation
  reseller_org: SimOrganisation
  model: Model
  profile_type: SimProfile
  imsi: String
  msisdn: String
  eid: String
  satellite_capable: Boolean
}

type SimPaging {
  items: [Sim!]

  """Contains the pagination information."""
  meta: ListMetaObject!
}

type ServiceProfile {
  id: Float!
  name: String!
}

type Endpoint {
  id: Float!
  name: String
  organisation_id: Float!
  ip_address: String
  created: DateTime!
  last_updated: DateTime!
  ip_address_space: Float
  status: EndpointStatus!
  tags: String
  imei: String
  imei_lock: Boolean
  sims: [Sim!]
  service_profile: ServiceProfile
  tariff_profile: TariffProfile
  connectivity: Connectivity
  stats(skipLastHour: Boolean! = true): Stats
  stats_daily(start: DateTime, end: DateTime): StatsDaily
  sim: Sim
}

type EndpointPaging {
  items: [Endpoint!]

  """Contains the pagination information."""
  meta: ListMetaObject!
}

type Volume {
  total: Float!
  rx: Float!
  tx: Float!
}

type SatelliteVolume {
  total: Float!
  rx: Float!
  tx: Float!

  """total consumed data in message unit"""
  total_messages: Int
}

type StatsDailyEntry {
  date: String!
  data_volume: Volume! @deprecated(reason: "Use data instead")
  sms_volume: Volume! @deprecated(reason: "use sms instead")
  sat_geo_data_volume: SatelliteVolume @deprecated(reason: "use sat_geo_data instead")
  data: Volume!
  sms: Volume!
  sat_geo_data: SatelliteVolume!
}

type StatsDailyTotalEntry {
  data_volume: Volume! @deprecated(reason: "Use data instead")
  sms_volume: Volume! @deprecated(reason: "use sms instead")
  sat_geo_data_volume: SatelliteVolume @deprecated(reason: "use sat_geo_data instead")
  data: Volume!
  sms: Volume!
  sat_geo_data: SatelliteVolume!
}

type StatsDaily {
  """Workspace ID"""
  id: Float!
  days: [StatsDailyEntry!]!

  """Total volume for the workspace"""
  total: StatsDailyTotalEntry!
}

type MonthDataObject {
  month: DateTime!
  data_volume: Volume!
  sms_volume: Volume!
  sat_geo_data_volume: SatelliteVolume
}

type MeasurementPoint {
  time: DateTime!
  data_volume: Volume!
  sms_volume: Volume!
  sat_geo_data_volume: Volume!
}

type Stats {
  id: Float!
  month: [MonthDataObject!]
  last_hour: [MeasurementPoint!]
  current_month: MonthDataObject
}

type RoleObject {
  id: Int!
  name: String!
}

type UserStatus {
  id: Int!
  description: String!
}

type UserWorkspaceAssignment {
  id: Int!
  is_default: Boolean!
  workspace: Organisation!
  status: UserStatus!
  invitation_expiry_date: DateTime
  owner: Boolean
  roles: [RoleObject!]
}

type AppliedPriceType {
  rate: Float!
  volume: Float!
  scale_start: Float
  price_model: String!
}

type AppliedPrice {
  sim_activated_rate: AppliedPriceType!
}

type ServiceLevelObject {
  id: Int!
  name: String!
}

type TariffPlanScalePriceObject {
  id: Int!
  tariff_plan_id: Float!
  scale_start: Float!
  hosting_fee: Float!
}

type TariffPlanObject {
  id: Int!
  name: String!
  description: String
  creation_date: DateTime
  activation_date: DateTime
  deprecation_date: DateTime
  expiry_time: Float!
  service_level: ServiceLevelObject
  federation_allowed: Boolean!
  evaluation: Boolean!
  vpn_access: Boolean!
  api_access_allowed: Boolean!
  currency: Currency
  display_upgrade_plan: Boolean!
  rate: Float!
  yearly_rate: Float!
  scale_prices: [TariffPlanScalePriceObject!]
}

type TariffPlanRuntimeObject {
  id: Int!
  number_of_units: Float!
  time_unit_id: Float!
}

type OrganisationTariffPlanAssignmentObject {
  id: Int!
  tariff_plan_id: Float
  tariff_plan: TariffPlanObject!
  start_date: DateTime!
  expiry_date: DateTime
  tariff_plan_runtime_id: Float!
  tariff_plan_runtime: TariffPlanRuntimeObject!
  currency: Currency
  service_level: ServiceLevelObject
  federation_allowed: Boolean
  factory_test_mode_allowed: Boolean
  sim_activated_rate: Float
  active: Boolean!
  sim_activated_amount: Int
  applied_price: AppliedPrice
  applied_rate: Float
}

type StatsStatus {
  ENABLED: Float!
  DISABLED: Float!
  ONLINE: Float!
}

type MfaKeyStatus {
  id: Int!
  description: String!
}

type MfaKeyType {
  id: Int!
  description: String!
}

type MfaKey {
  id: Int!
  status: MfaKeyStatus!
  type: MfaKeyType!
  created: DateTime!
  activated: DateTime
}

type UserOrganisation {
  id: Int!
  name: String!
  type: OrganisationType!
  created: DateTime!
  monthly_cost_limit: Float
  status: OrganisationStatus!
  federation_provider: FederationProvider!
  active_tariff_plan: OrganisationTariffPlanAssignmentObject
  endpoints_stats_status: StatsStatus
  federation_active: Boolean!
}

type User {
  id: Int!
  default_workspace_id: Float
  default_workspace: UserOrganisation
  created: DateTime!
  mfa_keys: [MfaKey!]
  username: String!
  name: String
  last_password_update: DateTime!
  default_workspace_status: UserStatus!
  last_activation_request: DateTime!
  deleted: DateTime
  verified: Boolean!
  default_workspace_roles: [RoleObject!]!
  invitation_expiry_date: DateTime

  """organisation object"""
  organisation: UserOrganisation @deprecated(reason: "Replaced by default_workspace")

  """Returns true if MFA is enabled for the user"""
  mfa_active: Boolean!

  """
  Returns the type of the currently selected MFA method, returns null if MFA is disabled
  """
  selected_mfa_type: MfaKeyType
  organisation_id: Int!
  status: UserStatus! @deprecated(reason: "will be replaced by default_workspace_status")
  roles: [RoleObject!]! @deprecated(reason: "will be replaced by default_workspace_status")

  """Default workspace are not applied in filter"""
  workspace_assignments: [UserWorkspaceAssignment!]

  """Used to list all the user admin workspaces, not applied with filter."""
  admin_workspaces: [UserWorkspaceAssignment!]
}

type UserPaging {
  items: [User!]

  """Contains the pagination information."""
  meta: ListMetaObject!
}

type IdPayload {
  id: Float!
}

type DeleteUserPayload {
  id: Float!
  workspace: IdPayload!
}

type DeleteUserMfaKeyPayload {
  user: IdPayload!
  mfa_key: IdPayload!
}

type RateZone {
  id: Int!
  name: String!

  """Returns true if the rate zone is satellite capable"""
  is_satellite_capable: Boolean!
}

type RateZoneList {
  list: [RateZone!]!
}

type DataPlan {
  id: Int!
  name: String!

  """The list of active rate zones of the data plan"""
  rate_zones: RateZoneList!
}

type DataPlanList {
  data_plans: [DataPlan!]!
}

type Query {
  """
  Returns the list of endpoints filtered, sorted, and paged according to arguments.
      Additionally, this resolver can return connectivity and usage statistics for the current or last month/hour.
  """
  endpoints(
    """
    Sort values in a comma-separated list from the following fields:
          - status
          - service_profile
          - tariff_profile
          - last_updated
          - created
          - name
          - tags
          - id
          - ip_address
          - imei
          - eid
          - iccid
          - iccid_with_luhn
    """
    sort: String! = ""

    """
    Filter argument in \`<field>:<criteria>\` format. Expects a comma-separated list from the following fields:
          - status
          - service_profile
          - tariff_profile
          - last_updated
          - created
          - name
          - tags
          - ip_address
          - imei
          - sim_status
          - eid
          - iccid
          - iccid_with_luhn
    
        Examples:
          - Filter by name and ID: \`name:Endpoint 38,id:1\`
          - Use the \`||\` operator to filter by one field with multiple values: \`name:Endpoint 38||Endpoint 40\`
    """
    filter: String! = ""

    """Current page number"""
    page: Int! = 1

    """Number of items per page (max. 500)"""
    per_page: Int! = 25
  ): EndpointPaging!

  """
  Similar to the \`endpoints\` query, but with more advanced filtering options that allow \`and\`/\`or\` conditions.
  - Example using or: 
     \`\`\`
    {
      endpointsQuery(
        filter: {
          name: {like: "%test%"},
          or: [{iccid: {like: "89883030%"}}]}
      ) {
        items {
          name
          sim {
            iccid
            }
          }
        }
    }
  """
  endpointsQuery(
    """Current page number"""
    page: Int! = 1

    """Number of items per page (max. 500)"""
    per_page: Int! = 25

    """Specify to filter the records returned."""
    filter: EndpointQueryObjectFilter! = {}

    """Specify to sort results."""
    sorting: [EndpointQueryObjectSort!]! = []
  ): EndpointPaging!

  """Retrieves endpoint details for a given ID."""
  endpoint(id: Int!): Endpoint!

  """
  Return paginated list of users.
  - Example using or to filter on name like \`user\` and workspaces id in workspace or default_workspace: 
       \`\`\`
      {
        users(
          filter: {
            name: {like: "%user%"}
            or: [
                  {default_workspace: {in: [44, 56, 21, 2] }}
                  {workspace: {in: [44, 56, 21, 2] }}
                ]
          }
        ) {
            items {
              name
              default_workspace{
                name
              }
              workspace_assignments{
                workspace {
                  name
                }
              }
            }
          }
      }
  """
  users(
    """Current page number"""
    page: Int! = 1

    """Number of items per page (max. 500)"""
    per_page: Int! = 25

    """Specify to filter the records returned."""
    filter: UserQueryObjectFilter! = {}

    """Specify to sort results."""
    sorting: [UserQueryObjectSort!]! = []
  ): UserPaging!

  """
  Returns your user and organization details, including endpoint status and active tariff plan.
  """
  myUser: User!

  """
  Returns a list of SIMs filtered, sorted, and paged according to arguments.
    Additionally, this resolver includes the endpoint object associated with each SIM.
  - Example using or: 
     \`\`\`
    {
      simsQuery(
        filter: {
          eid: {like: "%123%"},
          or: [{iccid: {like: "89883030%"}}]}
      ) {
        items {
            id
            iccid_with_luhn
          }
        }
    }
  """
  sims(
    """Current page number"""
    page: Int! = 1

    """Number of items per page (max. 500)"""
    per_page: Int! = 25

    """Specify to filter the records returned."""
    filter: SimQueryObjectFilter! = {}

    """Specify to sort results."""
    sorting: [SimQueryObjectSort!]! = []
  ): SimPaging!

  """
  Returns a list of SIMs filtered, sorted, and paged according to arguments.
    Additionally, this resolver includes the endpoint object associated with each SIM.
  """
  simsLegacy(
    """
    Sort values in a comma-separated list from the following fields:
          - id
          - issuer_org
          - reseller_org
          - customer_org
          - iccid
          - status
          - production_date
          - endpoint
          - model
          - eid
          - iccid
          - iccid_with_luhn
    """
    sort: String! = ""

    """
    Filter argument in \`<field>:<criteria>\` format. Expects a comma-separated list from the following fields:
          - id
          - issuer_org
          - reseller_org
          - customer_org
          - iccid
          - status
          - production_date
          - imsi
          - msisdn
          - endpoint
          - model
          - eid
          - iccid
          - iccid_with_luhn
        Examples:
          - Filter by name and ID: \`name:Endpoint 38,id:1\`
          - Use the \`||\` operator to filter by one field with multiple values: \`name:Endpoint 38||Endpoint 40\`
    """
    filter: String! = ""

    """Current page number"""
    page: Int! = 1

    """Number of items per page (max. 500)"""
    per_page: Int! = 25
  ): SimPaging!

  """Get daily stats for multi workspaces"""
  statsWorkspaceDaily(
    """If not set, default is the start of the month."""
    start_date: DateTime

    """If not set, the end of the current day."""
    end_date: DateTime

    """Specify to filter the records returned."""
    filter: DailyStatsQueryFilter! = {}
  ): [StatsDaily!]!

  """List of assigned data plans for the organisation of the current user.\n      Example:\n      \n      \n      query DataPlanListQuery($workspaceId: Int!) {\n        workspaceDataPlans(workspaceId: $workspaceId) {\n          data_plans {\n            id\n            name\n            rate_zones {\n              list {\n                id\n                name\n                is_satellite_capable\n              } \n            }\n          }\n        }\n      }\n      """
  workspaceDataPlans(
    """The ID of an workspace(organisation) to list data plans for."""
    workspaceId: Int
  ): DataPlanList!
}

input EndpointQueryObjectFilter {
  and: [EndpointQueryObjectFilter!]
  or: [EndpointQueryObjectFilter!]
  id: EndpointQueryObjectIdFilterComparison
  name: EndpointQueryObjectNameFilterComparison
  tags: EndpointQueryObjectTagsFilterComparison
  ip_address: EndpointQueryObjectIp_addressFilterComparison
  created: EndpointQueryObjectCreatedFilterComparison
  last_updated: EndpointQueryObjectLast_updatedFilterComparison
  iccid: EndpointQueryObjectIccidFilterComparison
  iccid_with_luhn: EndpointQueryObjectIccid_with_luhnFilterComparison
  eid: EndpointQueryObjectEidFilterComparison
  sim_status: EndpointQueryObjectSim_statusFilterComparison
  sim: EndpointQueryObjectSimFilterComparison
  imsi_pool: EndpointQueryObjectImsi_poolFilterComparison
  msisdn: EndpointQueryObjectMsisdnFilterComparison
  status: EndpointQueryObjectStatusFilterComparison
  imei: EndpointQueryObjectImeiFilterComparison
  service_profile: EndpointQueryObjectService_profileFilterComparison
  tariff_profile: EndpointQueryObjectTariff_profileFilterComparison
}

input EndpointQueryObjectIdFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input EndpointQueryObjectNameFilterComparison {
  eq: String
  like: String
  notLike: String
}

input EndpointQueryObjectTagsFilterComparison {
  eq: String
  like: String
  notLike: String
}

input EndpointQueryObjectIp_addressFilterComparison {
  eq: String
  like: String
  notLike: String
}

input EndpointQueryObjectCreatedFilterComparison {
  eq: DateTime
  like: DateTime
}

input EndpointQueryObjectLast_updatedFilterComparison {
  eq: DateTime
  like: DateTime
}

input EndpointQueryObjectIccidFilterComparison {
  eq: String
  like: String
  in: [String!]
}

input EndpointQueryObjectIccid_with_luhnFilterComparison {
  eq: String
  like: String
  in: [String!]
}

input EndpointQueryObjectEidFilterComparison {
  eq: String
  like: String
  in: [String!]
}

input EndpointQueryObjectSim_statusFilterComparison {
  is: Boolean
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input EndpointQueryObjectSimFilterComparison {
  is: Boolean
  isNot: Boolean
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input EndpointQueryObjectImsi_poolFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
}

input EndpointQueryObjectMsisdnFilterComparison {
  eq: String
  like: String
  notLike: String
  in: [String!]
}

input EndpointQueryObjectStatusFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input EndpointQueryObjectImeiFilterComparison {
  eq: String
  like: String
  notLike: String
}

input EndpointQueryObjectService_profileFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input EndpointQueryObjectTariff_profileFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input EndpointQueryObjectSort {
  field: EndpointQueryObjectSortFields!
  direction: SortDirection!
  nulls: SortNulls
}

enum EndpointQueryObjectSortFields {
  id
  name
  tags
  ip_address
  created
  last_updated
  iccid
  iccid_with_luhn
  eid
  sim_status
  sim
  imsi_pool
  msisdn
  status
  imei
  service_profile
  tariff_profile
}

"""Sort Directions"""
enum SortDirection {
  ASC
  DESC
}

"""Sort Nulls Options"""
enum SortNulls {
  NULLS_FIRST
  NULLS_LAST
}

input UserQueryObjectFilter {
  and: [UserQueryObjectFilter!]
  or: [UserQueryObjectFilter!]
  id: UserQueryObjectIdFilterComparison
  status: UserQueryObjectStatusFilterComparison
  name: UserQueryObjectNameFilterComparison
  username: UserQueryObjectUsernameFilterComparison
  organisation: UserQueryObjectOrganisationFilterComparison
  default_workspace: UserQueryObjectDefault_workspaceFilterComparison
  workspace: UserQueryObjectWorkspaceFilterComparison
  created: UserQueryObjectCreatedFilterComparison
}

input UserQueryObjectIdFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input UserQueryObjectStatusFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input UserQueryObjectNameFilterComparison {
  eq: String
  like: String
  notLike: String
}

input UserQueryObjectUsernameFilterComparison {
  eq: String
  like: String
  notLike: String
}

input UserQueryObjectOrganisationFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input UserQueryObjectDefault_workspaceFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input UserQueryObjectWorkspaceFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input UserQueryObjectCreatedFilterComparison {
  eq: DateTime
  like: DateTime
}

input UserQueryObjectSort {
  field: UserQueryObjectSortFields!
  direction: SortDirection!
  nulls: SortNulls
}

enum UserQueryObjectSortFields {
  id
  status
  name
  username
  organisation
  default_workspace
  workspace
  created
}

input SimQueryObjectFilter {
  and: [SimQueryObjectFilter!]
  or: [SimQueryObjectFilter!]
  id: SimQueryObjectIdFilterComparison
  iccid: SimQueryObjectIccidFilterComparison
  iccid_with_luhn: SimQueryObjectIccid_with_luhnFilterComparison
  status: SimQueryObjectStatusFilterComparison
  eid: SimQueryObjectEidFilterComparison
  ip_address: SimQueryObjectIp_addressFilterComparison
  created: SimQueryObjectCreatedFilterComparison
  production_date: SimQueryObjectProduction_dateFilterComparison
  issuer_org: SimQueryObjectIssuer_orgFilterComparison
  reseller_org: SimQueryObjectReseller_orgFilterComparison
  customer_org: SimQueryObjectCustomer_orgFilterComparison
  model: SimQueryObjectModelFilterComparison
  formfactor: SimQueryObjectFormfactorFilterComparison
  profile_type: SimQueryObjectProfile_typeFilterComparison
  endpoint: SimQueryObjectEndpointFilterComparison
  imsi: SimQueryObjectImsiFilterComparison
  imsi_pool: SimQueryObjectImsi_poolFilterComparison
  msisdn: SimQueryObjectMsisdnFilterComparison
}

input SimQueryObjectIdFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input SimQueryObjectIccidFilterComparison {
  eq: String
  like: String
  in: [String!]
}

input SimQueryObjectIccid_with_luhnFilterComparison {
  eq: String
  like: String
  in: [String!]
}

input SimQueryObjectStatusFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
  notIn: [Int!]
}

input SimQueryObjectEidFilterComparison {
  eq: String
  like: String
  in: [String!]
}

input SimQueryObjectIp_addressFilterComparison {
  eq: String
  like: String
  notLike: String
}

input SimQueryObjectCreatedFilterComparison {
  eq: DateTime
  like: DateTime
}

input SimQueryObjectProduction_dateFilterComparison {
  eq: DateTime
  like: DateTime
}

input SimQueryObjectIssuer_orgFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
}

input SimQueryObjectReseller_orgFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
}

input SimQueryObjectCustomer_orgFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
}

input SimQueryObjectModelFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
}

input SimQueryObjectFormfactorFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
}

input SimQueryObjectProfile_typeFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
}

input SimQueryObjectEndpointFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
}

input SimQueryObjectImsiFilterComparison {
  eq: String
  like: String
  in: [String!]
}

input SimQueryObjectImsi_poolFilterComparison {
  eq: Int
  neq: Int
  in: [Int!]
}

input SimQueryObjectMsisdnFilterComparison {
  eq: String
  like: String
  notLike: String
  in: [String!]
}

input SimQueryObjectSort {
  field: SimQueryObjectSortFields!
  direction: SortDirection!
  nulls: SortNulls
}

enum SimQueryObjectSortFields {
  id
  iccid
  iccid_with_luhn
  status
  eid
  ip_address
  created
  production_date
  issuer_org
  reseller_org
  customer_org
  model
  formfactor
  profile_type
  endpoint
  imsi
  imsi_pool
  msisdn
}

input DailyStatsQueryFilter {
  and: [DailyStatsQueryFilter!]
  or: [DailyStatsQueryFilter!]
  workspace: DailyStatsQueryWorkspaceFilterComparison
}

input DailyStatsQueryWorkspaceFilterComparison {
  in: [Int!]
}

type Mutation {
  """Update the workspace the sim is attached to"""
  migrateSim(simWorkspaceMigrationInput: SimWorkspaceMigrationInput!): SimWorkspaceMigrationResponse!

  """Update the user status in the given workspace"""
  updateEndpoint(updateEndpointInput: UpdateEndpointInput!, id: Int!): Endpoint!

  """Invite a user with the given role to the given workspace"""
  inviteUser(inviteUserInput: InviteUserInput!): User!

  """
  Resend user invitation for user with status ActivationPending,
         if workspace id was not set, invitation will be sent to the default organisation
  """
  resendUserInvitation(resendUserInvitationInput: ResendUserInvitationInput!): User!

  """Update the user status in the given workspace"""
  updateUserStatus(updateUserStatusInput: UpdateUserStatusInput!): User!

  """Adding user role in the given workspace"""
  addUserRole(addUserRoleInput: UpdateUserRoleInput!): User!

  """Deleting user role in the given workspace"""
  deleteUserRole(deleteUserRoleInput: UpdateUserRoleInput!): User!

  """Switching user role in the given workspace"""
  switchUserRole(switchUserRoleInput: UpdateUserRoleInput!): User!

  """Deleting user from the given workspace"""
  deleteUser(deleteUserInput: DeleteUserInput!): DeleteUserPayload!

  """Deleting MFA key of the selected user"""
  deleteUserMfaKey(deleteUserMfaKeyInput: DeleteUserMfaKeyInput!): DeleteUserMfaKeyPayload!

  """Update the user status in the given workspace"""
  updateSim(updateSimInput: UpdateSimInput!, id: Int!): Sim!
}

input SimWorkspaceMigrationInput {
  """\n      List of SIMs to migrate.\n      Cannot be used in combination with the \`iccids\` or the \`iccid_range\` field.\n      The list may include up to 500 SIMs.\n    """
  sims: [IdObject!]

  """\n     List of ICCIDs to migrate.\n      Cannot be used in combination with the \`sims\` or the \`iccid_range\` field.\n      The list may include up to 500 ICCIDs.\n    """
  iccids: [String!]

  """\n     ICCID range to migrate.\n      Cannot be used in combination with the \`iccids\` or the \`sims\` field.\n      The range may include up to 500 ICCIDs.\n    """
  iccid_range: SimMigrationIccidRangeDto

  """\n     List of SIM statuses that are allowed to be migrated.\n      \n      If a SIM has a status that is not in this list, the request will be rejected.\n      If not provided, all SIM statuses are allowed.\n    """
  allowed_sim_statuses: [IdObject!]

  """\n   If set to \`true\`, the migration will be processed as a dry run and no changes will be made.\n    The response will contain a summary of the changes that would be executed.\n    If set to \`false\`, the migration will be processed.\n  """
  dry_run: Boolean = true

  """\n     Target workspace to migrate SIMs to.\n      Must be an enterprise workspace.\n      The calling user must have admin permissions on the target workspace.\n    """
  target_workspace: IdObject!

  """\n     If set to \`true\`, the SIMs will automatically detach from the devices before being migrated.\n      Otherwise, the request is rejected if one or more SIMs are attached to devices.\n    """
  auto_detach_devices: Boolean
}

input IdObject {
  id: Float!
}

input SimMigrationIccidRangeDto {
  """First ICCID in the range."""
  start: String!

  """Last ICCID in the range."""
  end: String!
}

input UpdateEndpointInput {
  name: String
  tags: String
  status: EndpointStatusUpdateInput
  sim: EndpointSimUpdateInput
  service_profile: IdInput
  tariff_profile: IdInput
  ip_address: String
  ip_address_space: IdInput
  imei: String
  imei_lock: Boolean
}

input EndpointStatusUpdateInput {
  id: Int!
}

input EndpointSimUpdateInput {
  id: Int
}

input IdInput {
  id: Int!
}

input InviteUserInput {
  username: String!
  name: String
  organisation: IdInput
  roles: [IdInput!]
}

input ResendUserInvitationInput {
  user: IdInput!
  workspace: IdInput
}

input UpdateUserStatusInput {
  status: IdInput!
  user: IdInput!
  workspace: IdInput!
}

input UpdateUserRoleInput {
  role: IdInput!
  user: IdInput!
  workspace: IdInput!
}

input DeleteUserInput {
  id: Int!
  workspace: IdInput!
}

input DeleteUserMfaKeyInput {
  user: IdInput!
  mfa_key: IdInput!
}

"""Update SIM input"""
input UpdateSimInput {
  status: SimStatusUpdateInput
  customer_org: IdInput
  reseller_org: IdInput
  issuer_org: IdInput
}

input SimStatusUpdateInput {
  id: Int!
}`;
export default schema;
